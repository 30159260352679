import { styled } from '@mui/material';
import { Box } from '@mui/system';

import backgroundImage from '../../assets/qr/gr-launch-background.png';

const QrBackgroundImage = styled(Box)(
  ({ theme: { palette } }) => `
  position: absolute;

  min-width: 101%;
  min-height: 101%;
  aspect-ratio: 761/706;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`,
);

export default QrBackgroundImage;
